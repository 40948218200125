import { css } from '@emotion/core';
import variables from 'Theme/styles/vars';

export default (props, state) =>
    css({
        minHeight: '100vh',
        padding: '8em 0',
        position: 'relative',

        '.image-bg': {
            backgroundImage: `url(${require('Theme/images/couponRegister/bg.jpg')})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%',
            height: '80%',
            position: 'absolute',
            bottom: 0,
            zIndex: 0,
        },

        '.form-section-container': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',

            '.headline-container': {
                width: '50%',
                maxWidth: '50em',
                padding: '0 2em',
                position: 'relative',
                zIndex: 1,

                '.logo': {
                    width: '100%',
                    maxWidth: '25em',
                },
                '.headline': {
                    marginTop: '1.5em',
                    lineHeight: '100%',
                    color: 'white',
                    fontSize: '4em',
                    fontWeight: '900',
                    textTransform: 'uppercase',

                    '.sub': {
                        fontSize: '80%',
                        fontWeight: '300',
                        display: 'block',
                    },
                },
                '.subheadline': {
                    color: 'white',
                    marginTop: '1em',
                    fontSize: '1.2em',
                },
            },
            '.form-container': {
                width: '50%',
                padding: '0 2em',
                maxWidth: '30em',
                position: 'relative',
                zIndex: 1,

                '.input': {
                    '.input-label': {
                        color: 'white',
                    },
                    '.input-internal-wrapper': {
                        border:' .1em solid #FFFFFF',

                        input: {
                            color: 'white',
                            backgroundColor:' #000000',
                        },
                    },
                },
                '.component-button': {
                    marginTop: '2em',
                },
            },
        },

        [`@media all and (max-width: ${variables.tabletL})`]: {
            padding: '2em 0 0 0',

            '.image-bg': {
                height: '60%',
            },

            '.form-section-container': {
                flexDirection: 'column',
                paddingBottom: '10em',

                '.headline-container': {
                    width: '100%',
                    padding: '2em 0',
                    textAlign: 'center',
                    fontSize: '80%',

                    '.logo': {
                        margin: '0 auto',
                    },
                },
                '.form-container': {
                    width: '100%',
                    padding: '2em 0',
                },
            },
        },
        [`@media all and (max-width: ${variables.mobileL})`]: {
            '.form-section-container': {
                '.headline-container': {
                    fontSize: '65%',
                },
            },
        },
    });
