import React, { Component } from 'react';
import PropTypes from 'prop-types';

import StyledComponent from 'Components/core/StyledComponent';
import Head from 'Components/core/Head';
import FormSection from 'Components/public/couponRegister/FormSection';

export default class PublicCouponRegister extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        return (
            <StyledComponent
                className="component-coupon-register"
                styles={require('./styles')}
            >
                <Head
                    title="Karta podarunkowa"
                    description="Wykorzystaj kartę podarunkową FitAdept"
                />
                <FormSection />
            </StyledComponent>
        );
    }
}
